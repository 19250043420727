<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="action" slot-scope="{ columnData }">
			<a @click="apply(columnData.loanId)">{{ $t('page.edit') }}</a>
			<a-divider type="vertical" />
			<a @click="checkDetail(columnData.loanId)">{{ $t('page.checkSubmit') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiEmergencyList } from '@/api/emergencyLoan'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			branchOfficeList: []
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.caseId',
					type: 'input',
					validateField: ['caseId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.applyInfo.applicationLabel.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.statusOfRenew',
					type: 'select',
					validateField: ['processStatuses'],
					selectOption: ['apply_info_input', 'apply_info_check', 'apply_info_check_fail'],
					prefix: 'table.applyInfo.recordCloums.nodeStatus'
				},
				{
					label: 'table.applyInfo.cloums.createTime',
					type: 'range-picker',
					validateField: ['createTime', { initialValue: [] }]
				},
				{
					label: 'table.CustomerManagement.loanType',
					type: 'select',
					validateField: ['loanType'],
					selectOption: [5, 6],
					prefix: 'table.applyInfo.financialLabel.loanMap'
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'loanId',
					align: 'center',
					width: 150,
					fixed: 'left',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					title: this.$t('table.CustomerManagement.loanType'),
					dataIndex: 'loanType',
					align: 'center',
					customRender: (v) => this.$t(`table.applyInfo.financialLabel.loanMap.${v}`)
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'regNo',
					align: 'center',
					customRender: (v) => (v ? v : '-')
				},
				{
					// 联系方式
					title: this.$t('table.applyInfo.cloums.mobile'),
					dataIndex: 'mobiles',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '')
				},
				{
					// 身份ID
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 200,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiEmergencyList(initParams)
		},
		apply(id) {
			this.$router.push({
				path: 'applyInfo',
				query: {
					id,
					from: this.$route.path
				}
			})
		},
		checkDetail(id) {
			this.$router.push({
				path: 'detail',
				query: {
					id,
					from: this.$route.path
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
